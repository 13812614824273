<div class="dialog--title">
    <h6>
        {{ sysText.leaveCalendarWarning | titlecase }}
    </h6>
    <ng-container *ngIf="isHandheld">
      <span class="spacer"></span>
      <mat-icon role="button" (click)="close()" class="icon__button">close</mat-icon>
    </ng-container>
</div>

<mat-dialog-content>
    <div class="subtitle1">
        <!-- message for hidden or closed case is always shown -->

        <p class="body2 dialog-section" *ngIf="caseDetails.isHidden">{{ sysText.leaveCalendarWarning_hidden_subtitle }}</p>
        <p class="body2 dialog-section" *ngIf="!caseDetails.isHidden && caseDetails.isClosed">{{ sysText.leaveCalendarWarning_closed_subtitle }}</p>

        <!-- message for missing requirements or error conditions (only one shown) -->

        <p class="body2 dialog-section" *ngIf="caseDetails.showCalendarOverlayForAdmins && caseDetails.leaveCalendar.missingCalendarRequirements">{{ sysText.leaveCalendarWarning_subtitle }}</p>
        <p class="body2 dialog-section" *ngIf="caseDetails.showCalendarOverlayForAdmins && !caseDetails.leaveCalendar.missingCalendarRequirements && caseDetails.leaveCalendar.hasCalendarErrors">{{ sysText.leaveCalendarErrors_subtitle }}</p>
    </div>
    
    <div *ngIf="caseDetails.showCalendarOverlayForAdmins && (caseDetails.leaveCalendar.missingCalendarRequirements || caseDetails.leaveCalendar.hasCalendarErrors)">
        <!-- case settings missing -->

        <p class="body2" *ngIf="caseDetails.leaveInformation.currentStageId > leaveStages.ReadyForLeave">
            <span class="bullet-space">&bull;</span>{{ sysText.onOrAfterReadyForLeave }}
        </p>
        <p class="body2" *ngIf="caseDetails.leaveInformation.leaveType == null">
            <span class="bullet-space">&bull;</span>{{ sysText.leaveTypeRequired }}
        </p>
        <p class="body2" *ngIf="caseDetails.leaveInformation.startDate == null">
            <span class="bullet-space">&bull;</span>{{ sysText.startDateRequired }}
        </p>
        <p class="body2" *ngIf="caseDetails.leaveInformation.endDate == null">
            <span class="bullet-space">&bull;</span>{{ sysText.endDateRequired }}
        </p>

        <!-- employee settings missing -->

        <p class="body2" *ngIf="caseDetails.employeeInformation.isAnonymous">
            <span class="bullet-space">&bull;</span>{{ sysText.employeeRequired }}
        </p>
        <p class="body2" *ngIf="caseDetails.leaveInformation.isContinuous == true && (caseDetails.employeeInformation.employeeHoursPerWeek == null || caseDetails.employeeInformation.employeeHoursPerWeek <= 0)">
            <span class="bullet-space">&bull;</span>{{ sysText.hoursPerWeekRequired }}
        </p>
        
        <p class="body2" *ngIf="caseDetails.leaveCalendar.missingDateOfHire">
            <span class="bullet-space">&bull;</span>{{ sysText.dateOfHireRequired }}
        </p>
        <p class="body2" *ngIf="caseDetails.leaveCalendar.missingWorkingInState">
            <span class="bullet-space">&bull;</span>{{ sysText.workingInStateRequired }}
        </p>

        <p class="body2" *ngIf="caseDetails.leaveInformation.isFmlaHoursApplicable && caseDetails.leaveHours.employeeFmlaTotalHours == null">
            <span class="bullet-space">&bull;</span>{{ sysText.fmlaTotalHoursRequired }}
        </p>
        <p class="body2" *ngIf="caseDetails.leaveInformation.isStateHoursApplicable && caseDetails.leaveHours.employeeStateTotalHours == null">
            <span class="bullet-space">&bull;</span>{{ sysText.stateTotalHoursRequired }}
        </p>
        <p class="body2" *ngIf="caseDetails.leaveInformation.isPloHoursApplicable && caseDetails.leaveHours.employeePloTotalHours == null">
            <span class="bullet-space">&bull;</span>{{ sysText.ploTotalHoursRequired }}
        </p>

        <p class="body2" *ngIf="caseDetails.leaveCalendar.malformedEmployerLeaveYearRules">
            <span class="bullet-space">&bull;</span>{{ sysText.malformedEmployerLeaveYearRules }}
        </p>
        <p class="body2" *ngIf="caseDetails.leaveCalendar.missingFmlaLeaveYear">
            <span class="bullet-space">&bull;</span>{{ sysText.fmlaLeaveYearRequired }}
        </p>
        <p class="body2" *ngIf="caseDetails.leaveCalendar.missingStateLeaveYear">
            <span class="bullet-space">&bull;</span>{{ sysText.stateLeaveYearRequired }}
        </p>
        <p class="body2" *ngIf="caseDetails.leaveCalendar.missingFixedStart">
            <span class="bullet-space">&bull;</span>{{ sysText.fixedStartRequired }}
        </p>

        <!-- error conditions -->

        <p class="body2" *ngIf="caseDetails.leaveCalendar.leavesOutsideCaseTimeframe">
            <span class="bullet-space">&bull;</span>{{ sysText.leavesOutsideCaseTimeframe }}
        </p>
        <p class="body2" *ngIf="caseDetails.leaveCalendar.leavesOnNonWorkdays">
            <span class="bullet-space">&bull;</span>{{ sysText.leavesOnNonWorkdays }}
        </p>           
    </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!isHandheld" class="actions" align="end">
    <button mat-stroked-button class="button" [mat-dialog-close]="true" color="accent">
        {{ sysText.close }}
    </button>
</mat-dialog-actions>
