<div class="dialog--title">
	<h6 >
		{{ data.mode == leaveYearRulesMode.Add ? data.sysText.addLeaveYearRule_title : data.sysText.editLeaveYearRule_title }}
</h6>
	<ng-container *ngIf="isHandheld">
		<span class="spacer"></span>
		<mat-icon role="button" (click)="close(false)" class="icon__button">close</mat-icon>
	</ng-container>
</div>

<mat-dialog-content>
	<form [formGroup]="form" (ngSubmit)="onSubmit()">
		<app-date-field
			[group]="form"
			[control]="form.controls.effectiveDate"
			[icon]="'today'"
			[hint]="data.sysText.effectiveDateHint"
			[isHintHighlighted]="false"
			[label]="data.sysText.effectiveDate"
			[info]="data.sysText.effectiveDate_Info">
		</app-date-field>
		<app-select-field
			[control]="form.controls.bank"
			[label]="data.sysText.bank"
			[options]="bankOptions"
			[showBlankOption]="true"
			[icon]="'assignment_turned_in'"
			[shouldCapitalize]="false"
			[shouldTitlecaseLabel]="false"
			[info]="data.sysText.bank_Info">
		</app-select-field>
		<app-select-field
			[control]="form.controls.workingInState"
			[label]="data.sysText.workingInState"
			[options]="data.dialogOptions.stateOptions"
			[showBlankOption]="true"
			[icon]="'assignment_turned_in'"
			[shouldCapitalize]="false"
			[shouldTitlecaseLabel]="false"
			[info]="data.sysText.workingInState_Info">
		</app-select-field>
		<app-select-field
			[control]="form.controls.leaveYear"
			[label]="data.sysText.leaveYear"
			[options]="data.dialogOptions.leaveYearOptions"
			[showBlankOption]="true"
			[icon]="'assignment_turned_in'"
			[shouldCapitalize]="false"
			[shouldTitlecaseLabel]="false"
			[info]="data.sysText.leaveYear_Info">
		</app-select-field>
		<app-text-area
			[control]="form.controls.fixedStart"
			[label]="data.sysText.fixedStart"
			[icon]="'today'"
			[rows]="1"
			[info]="data.sysText.fixedStart_Info">
		</app-text-area>
		<app-radio-button-group
			[control]="form.controls.restoreHours"
			[label]="data.sysText.restoreHours"
			[shouldCapitalizeLabel]="false"
			[icon]="'update'"
			[isStacked]="true"
			[isYesNo]="true"
			[info]="data.sysText.restoreHours_Info">
		</app-radio-button-group>
		<div *ngIf="form.controls.restoreHours.value && selectedBank?.showRestoreConditions" class="container restore-conditions">
			<div class="label">
				<p class="body2">{{ data.sysText.restoreConditions_Label.replace("@[bank]", data.sysText['bank_' + selectedBank?.id]) }}</p>
			</div>
			<mat-selection-list [formControl]="form.controls.restoreConditions">
				<mat-list-option *ngFor="let condition of selectedBank?.restoreConditions" class="restore-condition" [value]="condition.id">
					{{ condition.description }}
				</mat-list-option>
			</mat-selection-list>
		</div>
	</form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="actions" *ngIf="!(isSubmitting$ | async); else submitting">
	<button mat-stroked-button class="button" color="accent" (click)="close(false)">
		{{data.sysText.cancel}}
	</button>
	<button mat-stroked-button class="button" color="accent" 
		[disabled]="" (click)="onSubmit()">
		{{data.sysText.save}}
	</button>
</mat-dialog-actions>

<ng-template #submitting>
	<mat-dialog-actions align="end">
		<button mat-button class="button" type="button" color="accent" disabled>
			<mat-spinner diameter="30" color="accent"></mat-spinner>
		</button>
	</mat-dialog-actions>
</ng-template>