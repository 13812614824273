<div class="dialog--title">
  <h6>
    {{ data.event.start | date }}
    {{ data.event.extendedProps.eventType != this.calendarEventTypes.ActionItem && this.exhausted() ? '-' : '' }}
    <span *ngIf="data.event.extendedProps.eventType != this.calendarEventTypes.ActionItem && this.exhausted()" 
      class="exhausted-highlight">
      {{ data.sysText.exhausted }}
    </span>
  </h6>
  <mat-icon *ngIf="data.event.extendedProps.eventType != this.calendarEventTypes.ActionItem"
    class="info-icon"
    [matTooltip]="data.sysText.hoursItem_tooltip" 
    [matTooltipShowDelay]="500" 
    matTooltipPosition="after">
    info_outline
  </mat-icon>
  <ng-container *ngIf="isMobile">
    <span class="spacer"></span>
    <mat-icon 
      role="button" 
      (click)="close()" 
      class="icon__button">
      close
    </mat-icon>
  </ng-container>
</div>

<mat-dialog-content>
  <ng-container *ngIf="data.event.extendedProps.eventType == this.calendarEventTypes.ActionItem">
    <div class="main-content flex main-content-actionitem">
      <p class="body2">
        {{ data.event.title }} {{ data.sysText.due }}
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf="data.event.extendedProps.eventType != this.calendarEventTypes.ActionItem">

    <div class="section-header-container">
      <p class="overline grey1 semibold section-title">
        {{ data.sysText.thisDate }}
      </p>
      <mat-divider class="section-header-underline"></mat-divider>
    </div>

    <div class="main-content flex">
      <div class="column-content">

        <div *ngIf="fmlaApplies()" class="item-container">
          <div 
            class="item-label"
            matTooltip="{{ data.sysText.caseFmlaPeriod_tooltip }}">
            <p class="body2 grey2">
              {{ data.sysText.fmlaPeriod }}
            </p>
          </div>
          <div class="item-content">
            <p class="body2 grey1 semibold">
              {{ this.period(this.employeeLeaveHoursExtended.leaveCaseFmlaStartDate, this.employeeLeaveHoursExtended.leaveCaseFmlaEndDate) ?? "--" }}
            </p>
          </div>
        </div>

        <div *ngIf="stateApplies()" class="item-container">
          <div class="item-label">
            <p 
              class="body2 grey2"
              matTooltip="{{ data.sysText.caseStatePeriod_tooltip }}">
              {{ data.sysText.statePeriod }}
            </p>
          </div>
          <div class="item-content">
            <p class="body2 grey1 semibold">
              {{ this.period(this.employeeLeaveHoursExtended.leaveCaseStateStartDate, this.employeeLeaveHoursExtended.leaveCaseStateEndDate) ?? "--" }}
            </p>
          </div>
        </div>

        <div *ngIf="ploApplies()" class="item-container">
          <div 
            class="item-label"
            matTooltip="{{ data.sysText.casePloPeriod_tooltip }}">
            <p class="body2 grey2">
              {{ data.sysText.ploPeriod }}
            </p>
          </div>
          <div class="item-content">
            <p class="body2 grey1 semibold">
              {{ this.period(this.employeeLeaveHoursExtended.leaveCasePloStartDate, this.employeeLeaveHoursExtended.leaveCasePloEndDate) ?? "--" }}
            </p>
          </div>
        </div>

      </div>

      <div class="column-content">

        <div class="item-container">
          <div 
            class="item-label"
            matTooltip="{{ data.sysText.leavesTaken_tooltip + (hasCaseHoursToday() ? ' ' + data.sysText.includingThisCase : '') + (caseIsHidden() ? ' ' + data.sysText.excludingHiddenCase : '') }}">
            <p class="body2 grey2">
              {{ data.sysText.leavesTaken }}
            </p>
          </div>
          <div class="item-content">
            <p class="body2 grey1 semibold">
              {{ this.employeeLeaveHoursExtended.leavesCount ?? 0 }}
            </p>
          </div>
        </div>

        <div class="item-container">
          <div 
            class="item-label"
            matTooltip="{{ data.sysText.hoursTaken_tooltip + (hasCaseHoursToday() ? ' ' + data.sysText.includingThisCase : '') + (caseIsHidden() ? ' ' + data.sysText.excludingHiddenCase : '') }}">
            <p class="body2 grey2">
              {{ data.sysText.hoursTaken }}
            </p>
          </div>
          <div class="item-content">
            <p class="body2 grey1 semibold">
              {{ this.employeeLeaveHoursExtended.employeeLeaveHours ?? 0 }}
            </p>
          </div>
        </div>

      </div>
    </div>
    
    <div class="section-header-container">
      <p class="overline grey1 semibold section-title">
        {{ data.sysText.thisCase }}
      </p>
      <mat-divider class="section-header-underline"></mat-divider>
      <div *ngIf="outsideCaseTimeframe()" class="body2 grey2 warn">
        {{ data.sysText.outsideCaseTimeframeMessage }}
      </div>
      <div *ngIf="notWorkday()" class="body2 grey2 warn">
        {{ data.sysText.notWorkdayMessage }}
      </div>
      <div *ngIf="caseIsHidden()" class="body2 grey2">
        {{ data.sysText.hiddenCaseMessage }}
      </div>
    </div>

    <div class="main-content flex">
      <div class="column-content">

        <div class="item-container">
          <div 
            class="item-label" 
            matTooltip="{{ data.sysText.casehours_tooltip }}" >
            <p class="body2 grey2">
              {{ data.sysText.hours }}
            </p>
          </div>
          <div class="item-content">
            <p class="body2 grey1 semibold">
              {{ this.employeeLeaveHoursExtended.leaveCaseLeaveHours ?? "--" }}
            </p>
          </div>
        </div>

        <div class="item-container">
          <div 
            class="item-label"
            matTooltip="{{ data.sysText.used_tooltip + (hasCaseHoursToday() ? ' ' + data.sysText.includingThisDate : '') }}">
            <p class="body2 grey2">
              {{ data.sysText.used }}
            </p>
          </div>
          <div class="item-content">
            <p class="body2 grey1 semibold">
              {{ this.employeeLeaveHoursExtended.leaveCaseUsedHours ?? "--" }}
            </p>
          </div>
        </div>

        <div class="item-container">
          <div 
            class="item-label"
            matTooltip="{{ data.sysText.future_tooltip }}">
            <p class="body2 grey2">
              {{ data.sysText.future }}
            </p>
          </div>
          <div class="item-content">
            <p class="body2 grey1 semibold">
              {{ this.employeeLeaveHoursExtended.leaveCaseFutureHours ?? "--" }}
            </p>
          </div>
        </div>

      </div>

      <div class="column-content">

        <div class="item-container">
          <div 
            class="item-label" 
            matTooltip="{{ data.sysText.leaveType_tooltip }}">
            <p class="body2 grey2">
              {{ data.sysText.leaveType }}
            </p>
          </div>
          <div class="item-content">
            <p class="body2 grey1 semibold">
              {{ this.leaveInformation.leaveType?.description ?? "--" }}
              <span *ngIf="this.leaveInformation?.leaveSubtype != null"> ({{ this.leaveInformation?.leaveSubtype }})</span>
            </p>
          </div>
        </div>

        <div class="item-container">
          <div 
            class="item-label" 
            matTooltip="{{ data.sysText.draw_tooltip }}">
            <p class="body2 grey2">
              {{ data.sysText.draw }}
            </p>
          </div>
          <div class="item-content">
            <p class="body2 grey1 semibold">
              {{ this.banks() ?? "--" }}
            </p>
          </div>
        </div>

      </div>
    </div>

    <ng-container *ngIf="fmlaApplies()">

      <div class="section-header-container">
        <p class="overline grey1 semibold section-title">{{ data.sysText.fmlaSummary }}</p>
        <mat-divider class="section-header-underline"></mat-divider>
      </div>

      <div class="main-content flex">
        <div class="column-content">

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.fmlaLeaveYear_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.leaveYear }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ getLeaveYearName(this.employeeLeaveHoursExtended.fmlaLeaveYearID) }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.fmlaPeriod_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.period }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.period(this.employeeLeaveHoursExtended.fmlaStartDate, this.employeeLeaveHoursExtended.fmlaEndDate) ?? "--" }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.fmlaCaseUsed_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.caseUsed }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.leaveCaseFmlaUsedHours ?? "--" }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.fmlaCaseFuture_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.caseFuture }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.leaveCaseFmlaFutureHours ?? "--" }}
              </p>
            </div>
          </div>

        </div>

        <div class="column-content">

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.fmlaLeavesTaken_tooltip + (hasCaseHoursToday() ? ' ' + data.sysText.includingThisCase : '') + (caseIsHidden() ? ' ' + data.sysText.excludingHiddenCase : '') }}">
              <p class="body2 grey2">
                {{ data.sysText.leavesTaken }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.fmlaLeavesCount ?? 0 }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.fmlaLeavesDrawing_tooltip + (hasCaseHoursToday() ? ' ' + data.sysText.includingThisCase : '') + (caseIsHidden() ? ' ' + data.sysText.excludingHiddenCase : '') }}">
              <p class="body2 grey2">
                {{ data.sysText.leavesDrawing }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.fmlaDrawCount ?? 0 }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.fmlaEmployeeTotal_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.employeeTotal }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.employeeFmlaTotalHours ?? "--" }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.fmlaEmployeeUsed_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.employeeUsed }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.employeeFmlaUsedHours ?? "--" }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.fmlaEmployeeAdjustments_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.employeeAdjustments }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.employeeFmlaAdjustments ?? "--" }}
              </p>
            </div>
          </div>

          <div 
            class="item-container" 
            [ngClass]="{ 'item-container-exhausted': this.fmlaExhausted() }">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.fmlaEmployeeAvailable_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.employeeAvailable }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.employeeFmlaAvailableHours ?? "--" }}
                <span *ngIf="this.fmlaExhausted()" class="item-content-nobold">{{ data.sysText.exhaustedParen }}</span>
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.fmlaEmployeeFuture_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.employeeFuture }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.employeeFmlaFutureHours ?? "--" }}
              </p>
            </div>
          </div>

        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="stateApplies()">

      <div class="section-header-container">
        <p class="overline grey1 semibold section-title">{{ data.sysText.stateSummary }}</p>
        <mat-divider class="section-header-underline"></mat-divider>
      </div>

      <div class="main-content flex">
        <div class="column-content">

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.stateLeaveYear_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.leaveYear }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ getLeaveYearName(this.employeeLeaveHoursExtended.stateLeaveYearID) }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.statePeriod_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.period }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.period(this.employeeLeaveHoursExtended.stateStartDate, this.employeeLeaveHoursExtended.stateEndDate) ?? "--" }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.stateCaseUsed_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.caseUsed }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.leaveCaseStateUsedHours ?? "--" }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.stateCaseFuture_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.caseFuture }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.leaveCaseStateFutureHours ?? "--" }}
              </p>
            </div>
          </div>

        </div>

        <div class="column-content">

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.stateLeavesTaken_tooltip + (hasCaseHoursToday() ? ' ' + data.sysText.includingThisCase : '') + (caseIsHidden() ? ' ' + data.sysText.excludingHiddenCase : '') }}">
              <p class="body2 grey2">
                {{ data.sysText.leavesTaken }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.stateLeavesCount ?? 0 }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.stateLeavesDrawing_tooltip + (hasCaseHoursToday() ? ' ' + data.sysText.includingThisCase : '') + (caseIsHidden() ? ' ' + data.sysText.excludingHiddenCase : '') }}">
              <p class="body2 grey2">
                {{ data.sysText.leavesDrawing }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.stateDrawCount ?? 0 }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.stateEmployeeTotal_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.employeeTotal }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.employeeStateTotalHours ?? "--" }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.stateEmployeeUsed_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.employeeUsed }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.employeeStateUsedHours ?? "--" }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.stateEmployeeAdjustments_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.employeeAdjustments }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.employeeStateAdjustments ?? "--" }}
              </p>
            </div>
          </div>

          <div 
            class="item-container"
            [ngClass]="{ 'item-container-exhausted': this.stateExhausted() }">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.stateEmployeeAvailable_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.employeeAvailable }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.employeeStateAvailableHours ?? "--" }}
                <span *ngIf="this.stateExhausted()" class="item-content-nobold">{{ data.sysText.exhaustedParen }}</span>
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.stateEmployeeFuture_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.employeeFuture }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.employeeStateFutureHours ?? "--" }}
              </p>
            </div>
          </div>

        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="ploApplies()">

      <div class="section-header-container">
        <p class="overline grey1 semibold section-title">{{ data.sysText.ploSummary }}</p>
        <mat-divider class="section-header-underline"></mat-divider>
      </div>

      <div class="main-content flex">
        <div class="column-content">

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.ploLeaveYear_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.leaveYear }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ getLeaveYearName(this.ploLeaveYearId) }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.ploPeriod_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.period }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.period(this.employeeLeaveHoursExtended.ploStartDate, this.employeeLeaveHoursExtended.ploEndDate) ?? "--" }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.ploCaseUsed_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.caseUsed }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.leaveCasePloUsedHours ?? "--" }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.ploCaseFuture_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.caseFuture }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.leaveCasePloFutureHours ?? "--" }}
              </p>
            </div>
          </div>

        </div>

        <div class="column-content">

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.ploLeavesTaken_tooltip + (hasCaseHoursToday() ? ' ' + data.sysText.includingThisCase : '') + (caseIsHidden() ? ' ' + data.sysText.excludingHiddenCase : '') }}">
              <p class="body2 grey2">
                {{ data.sysText.leavesTaken }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.ploLeavesCount ?? 0 }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.ploLeavesDrawing_tooltip + (hasCaseHoursToday() ? ' ' + data.sysText.includingThisCase : '') + (caseIsHidden() ? ' ' + data.sysText.excludingHiddenCase : '') }}">
              <p class="body2 grey2">
                {{ data.sysText.leavesDrawing }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.ploDrawCount ?? 0 }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.ploEmployeeTotal_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.employeeTotal }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.employeePloTotalHours ?? "--" }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.ploEmployeeUsed_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.employeeUsed }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.employeePloUsedHours ?? "--" }}
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.ploEmployeeAdjustments_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.employeeAdjustments }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.employeePloAdjustments ?? "--" }}
              </p>
            </div>
          </div>

          <div 
            class="item-container"
            [ngClass]="{ 'item-container-exhausted': this.ploExhausted() }">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.ploEmployeeAvailable_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.employeeAvailable }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.employeePloAvailableHours ?? "--" }}
                <span *ngIf="this.ploExhausted()" class="item-content-nobold">{{ data.sysText.exhaustedParen }}</span>
              </p>
            </div>
          </div>

          <div class="item-container">
            <div 
              class="item-label"
              matTooltip="{{ data.sysText.ploEmployeeFuture_tooltip }}">
              <p class="body2 grey2">
                {{ data.sysText.employeeFuture }}
              </p>
            </div>
            <div class="item-content">
              <p class="body2 grey1 semibold">
                {{ this.employeeLeaveHoursExtended.employeePloFutureHours ?? "--" }}
              </p>
            </div>
          </div>

        </div>
      </div>

    </ng-container>

  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
      mat-stroked-button
      class="button"
      mat-dialog-close
      color="accent"
    >
      {{ data.sysText.close }}
    </button>
  </mat-dialog-actions>